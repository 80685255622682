import React, { useState } from 'react'
import { Table } from 'reactstrap'
import useSWR from 'swr'
import { buildUrl } from '../utils/utils';

const GLMData = (props) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  })

  const columns = [
    {
      title: 'Crossing Event ID',
      dataIndex: 'crossingEventId',
      key: 'crossingEventId',
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
    },
    {
      title: 'Gateway Mac Address',
      dataIndex: 'gatewayMacAddress',
      key: 'gatewayMacAddress',
    },
    {
      title: 'Node Mac Address',
      dataIndex: 'nodeMacAddress',
      key: 'nodeMacAddress',
    },
    {
      title: 'Gatearm Detected',
      dataIndex: 'gatearmDetected',
      key: 'gatearmDetected',
      render: (value) => String(value),
    },
    {
      title: 'Gatearm Down Angle',
      dataIndex: 'gatearmDownAngle',
      key: 'gatearmDownAngle',
    },
    {
      title: 'Gatearm Up Angle',
      dataIndex: 'gatearmUpAngle',
      key: 'gatearmUpAngle',
    },
    {
      title: 'Gatearm Descent Time',
      dataIndex: 'gatearmDescentTime',
      key: 'gatearmDescentTime',
    },
    {
      title: 'Gatearm Ascent Time',
      dataIndex: 'gatearmAscentTime',
      key: 'gatearmAscentTime',
    },
    {
      title: 'Tip Broken',
      dataIndex: 'tipBroken',
      key: 'tipBroken',
      render: (value) => String(value),
    },
    {
      title: 'Tip Light Detected',
      dataIndex: 'tipLightDetected',
      key: 'tipLightDetected',
      render: (value) => String(value),
    },
    {
      title: 'Middle Light Detected',
      dataIndex: 'middleLightDetected',
      key: 'middleLightDetected',
      render: (value) => String(value),
    },
    {
      title: 'Base Light Detected',
      dataIndex: 'baseLightDetected',
      key: 'baseLightDetected',
      render: (value) => String(value),
    },
    {
      title: 'Tip Light Blink Count',
      dataIndex: 'tipLightBlinkCount',
      key: 'tipLightBlinkCount',
    },
    {
      title: 'Middle Light Blink Count',
      dataIndex: 'middleLightBlinkCount',
      key: 'middleLightBlinkCount',
    },
    {
      title: 'Base Light Blink Count',
      dataIndex: 'baseLightBlinkCount',
      key: 'baseLightBlinkCount',
    },
    {
      title: 'Tip Light Flash Rate',
      dataIndex: 'tipLightFlashRate',
      key: 'tipLightFlashRate',
    },
    {
      title: 'Middle Light Flash Rate',
      dataIndex: 'middleLightFlashRate',
      key: 'middleLightFlashRate',
    },
    {
      title: 'Base Light Flash Rate',
      dataIndex: 'baseLightFlashRate',
      key: 'baseLightFlashRate',
    },
  ]

      const fetcher = async (url) => {
        const response = await fetch(url)
        const data = await response.json()
    
        return data.map((item) => ({
          key: item.Data.CrossingEventID,
          crossingEventId: item.Data.CrossingEventID,
          startTime: item.Data.StartTime,
          gatewayMacAddress: item.GatewayMacAddress,
          nodeMacAddress: item.Data.Value.NodeMacAddress,
          gatearmDetected: item.Data.Value.GatearmDetected,
          gatearmDownAngle: item.Data.Value.GatearmDownAngle,
          gatearmUpAngle: item.Data.Value.GatearmUpAngle,
          gatearmDescentTime: item.Data.Value.GatearmDescentTime,
          gatearmAscentTime: item.Data.Value.GatearmAscentTime,
          tipBroken: item.Data.Value.TipBroken,
          tipLightDetected: item.Data.Value.TipLightDetected,
          middleLightDetected: item.Data.Value.MiddleLightDetected,
          baseLightDetected: item.Data.Value.BaseLightDetected,
          tipLightBlinkCount: item.Data.Value.TipLightBlinkCount,
          middleLightBlinkCount: item.Data.Value.MiddleLightBlinkCount,
          baseLightBlinkCount: item.Data.Value.BaseLightBlinkCount,
          tipLightFlashRate: item.Data.Value.TipLightFlashRate,
          middleLightFlashRate: item.Data.Value.MiddleLightFlashRate,
          baseLightFlashRate: item.Data.Value.BaseLightFlashRate,
        }))
      } 
 const { data, error } = useSWR(buildUrl('/api/getglmdata', { mac_address: props.MacAddress }), fetcher)
  return (
    <div style={{ padding: '24px' }}>
      <div style={{ overflowX: 'auto' }}>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.key}>{column.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.slice((pagination.current - 1) * pagination.pageSize, pagination.current * pagination.pageSize).map(row => (
              <tr key={row.key}>
                {columns.map(column => (
                  <td key={`${row.key}-${column.key}`}>
                    {column.render ? column.render(row[column.dataIndex]) : row[column.dataIndex]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* Add custom pagination component here if needed */}
    </div>
  )
}

export default GLMData